import React from 'react';
import './ScrollTop.scss';
import * as Scroll from 'react-scroll';
import ArrowDown from '../../assets/arrow-down-wt.svg';

function ScrollTop() {
    const scrollToTop = () => {
        Scroll.animateScroll.scrollToTop();
    };
    return (
        <div className="scroll-top-component" onClick={scrollToTop}>
            <img src={ArrowDown} alt="Do góry" className="scroll-top-component__arrow" />
        </div>
    );
}

export default ScrollTop;
